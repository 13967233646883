<template>
  <div class="row">
    <div class="col-2 offset-8 text-center">
      <responsive-button responsive="xl"
                         variant="success"
                         icon="box-arrow-in-right"
                         text="Crear Entrada"
                         @ClickEvent="openStorageEntrance"
                         block
                         pill
      />
    </div>
    <div class="col-2 text-center">
      <responsive-button responsive="xl"
                         variant="danger"
                         icon="box-arrow-right"
                         text="Crear Salida"
                         @ClickEvent="openStorageOutput"
                         block
                         pill
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'transactionButtons',
  data() {
    return {}
  },
  methods: {
    openStorageEntrance() {
      this.$router.push({ name: 'reagentStorageEntrance', params: {} })
    },
    openStorageOutput() {
      this.$router.push({ name: 'reagentStorageOutput', params: {} })
    },
  },
}
</script>
