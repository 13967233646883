<template>
  <div>
    <div class="air__utils__heading"> <h5>Almacén de Reactivos</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row mb-5">
      <div class="col">
        <a-tabs default-active-key="1" type="card" size="large">
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="experiment"/>
              Existencias
            </span>
            <div class="card">
              <div class="card-body text-center">
                <reagent-input-table transaction-type="general" table-type="details"></reagent-input-table>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
              <span slot="tab">+
                <a-icon type="interaction"/>
                Transacciones
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <transaction-buttons/>
                  <hr class="bg-primary">
                  <reagent-transaction-table :table-object="tableObj"/>
                </div>
              </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionButtons from '@/views/geocycle/laboratories/reagent_warehouse/components/transactionButtons'
import ReagentTransactionTable from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentTransactionTable'
import ReagentInputTable from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentInputTable'
export default {
  name: 'reagentMain',
  components: { ReagentInputTable, ReagentTransactionTable, TransactionButtons },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Almacén de Reactivos',
          aIcon: '',
        },
      ],
      tableObj: {
        resourceType: 'reagentTransactions',
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Planta',
            dataIndex: 'workCenters',
            key: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Folio',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center font-weight-bold',
          },
          {
            title: 'Información de la Transacción:',
            dataIndex: 'users',
            key: 'users',
            class: 'small text-center',
            scopedSlots: { customRender: ['users'] },
          },
          {
            title: 'Fecha',
            dataIndex: '',
            key: 'fecha',
            class: 'small text-center font-weight-bold',
            scopedSlots: { customRender: 'datedAt' },
          },
          {
            title: 'Tipo de transacción:',
            dataIndex: 'warehouseTransactions',
            key: 'warehouseTransactions',
            class: 'small text-center',
            scopedSlots: { customRender: 'warehouseTransactions' },
          },
          {
            title: 'Proveedor de Reactivos:',
            dataIndex: '',
            key: 'reagentProviders',
            class: 'small text-center',
            scopedSlots: { customRender: 'reagentProviders' },
          },
          {
            title: 'Razón de Transacción:',
            dataIndex: '',
            key: 'reason_transaction',
            class: 'small text-center',
            scopedSlots: { customRender: 'reason' },
          },
          // {
          //   title: 'Acción',
          //   dataIndex: '',
          //   key: 'x',
          //   class: 'small text-center',
          //   scopedSlots: { customRender: 'action' },
          // },
        ],
        innerColumns: [
          {
            title: 'Nombre reactivo',
            dataIndex: 'reagents',
            key: 'reagentName',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'reagentName',
            },
          },
          // {
          //   title: 'Familia',
          //   dataIndex: '',
          //   key: 'reagentFam',
          //   class: 'small text-center',
          //   scopedSlots: {
          //     customRender: 'reagentFam',
          //   },
          // },
          {
            title: 'Cantidad',
            dataIndex: '',
            key: 'quantity',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'quantity',
            },
          },
          {
            title: 'Notas de Volumen',
            dataIndex: '',
            key: 'volume',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'volume',
            },
          },
          {
            title: 'Notas especiales',
            dataIndex: '',
            key: 'specialNotes',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'specialNotes',
            },
          },
        ],
      },
    }
  },
}
</script>
