<template>
  <div>
    <!--BOTONERA  -->
    <!-- <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div> -->
    <!--BOTONERA  -->
    <searchingBar :components="['filter', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="fetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObject.sortValue = $event"
                  @pageSize="tableObject.pagination.pageSize = $event"
                  @currentPageValue="tableObject.currentPage = $event"
    />
    <!-- <div class='row'>
      <div class="col-md-6 col-lg-3">
        <b-form-group id="plantGroup" label="Plantas" label-for="plants">
          <a-select v-model="filterPlant1" @change="fetchTable()" allowClear
                    placeholder="Todas las Plantas" style="width: 100%"
                    show-search :filter-option="filterOption">
            <a-select-option v-for="option in plantsCombo1" :key="option.id"
                              :value="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
    </div> -->
    <div class='row'>
      <a-col class="col-sm-12 col-md-6 col-lg-3">
        <globalPlantFilter @onChangeValue="fetchTable"  @filterValue="filterPlant1 = $event"/>
      </a-col>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="fromPickup-group" label="Fecha inicial"  label-for="fromPickup">
          <b-form-datepicker v-model="filterFromDate" @input="fetchTable()"
                             reset-button label-reset-button="Limpiar"
                             id="fromPickup" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="toPickup-group" label="Fecha final"  label-for="toPickup">
          <b-form-datepicker v-model="filterToDate" @input="fetchTable()"
                             reset-button label-reset-button="Limpiar"
                             id="toPickup" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </div>
    </div>

    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             :row-key="record => record.id"
    >
      <!-- <p slot="expandedRowRender" slot-scope="record">
        {{ record.reason_transaction }}
      </p> -->
      <a-table
      slot="expandedRowRender"
      slot-scope="record"
      :columns="tableObject.innerColumns"
      :data-source="record.reagentTransactionDetails"
      :pagination="false"
    >
        <template slot="reagentName" slot-scope="data">
          {{ `${data.code}-${data.name}`  }}
        </template>
        <template slot="reagentFam">
          {{ 'Familia movil' }}
        </template>
        <template slot="quantity" slot-scope="data">
            {{ data.quantity }}
        </template>
        <template slot="volume" slot-scope="data">
            {{ data.container_volume_notes }}
        </template>
        <template slot="specialNotes" slot-scope="data">
            {{ data.special_notes }}
        </template>
      </a-table>
      <template slot="workCenters" slot-scope="record">
        {{ record.code }} - {{ record.name }}
      </template>
      <template slot="reason" slot-scope="record">
        {{ (record.reason_transaction !== null) ? record.reason_transaction : '-' }}
      </template>
      <template slot="users" slot-scope="record">
        <div class="row font-weight-bold">
          <div class="col-12 text-center">
            Responsable:
          </div>
        </div>
        {{ record.name }}
        <!-- <hr class="bg-primary"> -->
      </template>
      <template slot="datedAt" slot-scope="record">
        <!-- <div class="row font-weight-bold">
          <div class="col-12 text-center">
            Fecha:
          </div>
        </div> -->
        <span>
          {{ moment.utc(moment(record.dated_at)).format('YYYY-MM-DD') }}
        </span>
      </template>
      <template slot="warehouseTransactions" slot-scope="record">
        <span v-if="record.transaction_type === 'Entrada'" class="font-weight-bold text-success">
          <b-icon icon="box-arrow-in-right" variant="success"/>
          {{ record.name }}
        </span>
        <span v-if="record.transaction_type === 'Salida'" class="font-weight-bold text-danger">
          <b-icon icon="box-arrow-right" variant="danger"/>
          {{ record.name }}
        </span>
      </template>
      <template slot="reagentProviders" slot-scope="record">
        {{ (record.reagentProviders !== null) ? (record.reagentProviders.name !== null ? record.reagentProviders.name : '-') : '-' }}
      </template>
      <template slot="action" slot-scope="data">
        <b-button variant="success"
                  @click="OpenModal(data)"
                  pill
        >
          <div class="d-none d--block"
               v-b-tooltip.hover
               title="Ver transacción"
          >
            <b-icon icon="arrow-right-circle-fill"/>
            Ver transacción
          </div>
          <div class="d--none"
               v-b-tooltip.hover
               title="Ver transacción"
          >
            <b-icon icon="arrow-right-circle-fill"/>
          </div>
        </b-button>
      </template>
    </a-table>

    <!-- MODAL -->
    <b-modal title="Detalle de Prueba" ref="testModal" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--// Formulario-->
        <a-form-model :model="resourceObj.form"
                      v-show="!spinnerLoad"
                      :rules="resourceRules"
                      ref="transactionForm" layout="vertical">

          <a-row align="middle" :gutter="[8,24]">
            <!--TEXT - Lote-->
            <a-col :sm="24">
              <a-form-model-item label="Lote" ref="code"  prop="code" v-if="resourceObj.form.code">
                <a-input v-model="resourceObj.form.code" disabled
                         placeholder="Lote" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--SELECT -Tipo de transacción  -->
            <a-col :sm="24" :md="{ span:24}">
              <a-form-model-item label="Tipo de transacción" ref="warehouseTransactions" prop="warehouseTransactions" >
                <ResourceSelect v-model="resourceObj.form.warehouseTransactions"
                                urlResource="/warehouseTransactions"
                                placeholder="Tipo de transacción">
                  <span slot-scope="{option}">{{option.name}}</span>
                </ResourceSelect>
              </a-form-model-item>
            </a-col>
            <!--SELECT -Centro de Trabajo  -->
            <a-col :sm="24" :md="{ span:24}">
              <a-form-model-item label="Centro de Trabajo" ref="workCenters" prop="workCenters" >
                <ResourceSelect v-model="resourceObj.form.workCenters"
                                urlResource="/workCenters"
                                placeholder="Centro de Trabajo">
                  <span slot-scope="{option}">{{option.name}}</span>
                </ResourceSelect>
              </a-form-model-item>
            </a-col>
            <!--SELECT -Proveedor de reactivo  -->
            <a-col :sm="24" :md="{ span:24}">
              <a-form-model-item label="Proveedor de reactivo" ref="reagentProviders" prop="reagentProviders" >
                <ResourceSelect v-model="resourceObj.form.reagentProviders"
                                urlResource="/reagentProviders"
                                placeholder="Proveedor de reactivo">
                  <span slot-scope="{option}">{{option.code}} - {{option.name}}</span>
                </ResourceSelect>
              </a-form-model-item>
            </a-col>
            <!--SELECT -Usuario  -->
            <a-col :sm="24" :md="{ span:24}">
              <a-form-model-item label="Usuario" ref="users" prop="users" >
                <ResourceSelect v-model="resourceObj.form.users"
                                urlResource="/users"
                                placeholder="Usuario">
                  <span slot-scope="{option}">{{option.name}}</span>
                </ResourceSelect>
              </a-form-model-item>
            </a-col>
            <!--DATE - Fecha de Transacción-->
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <a-form-model-item label="Fecha" ref="dated_at"  prop="dated_at">
                <a-date-picker v-model="resourceObj.form.dated_at"
                               :show-time="{ format: 'HH:mm' }"
                               format="YYYY-MM-DD HH:mm"
                               valueFormat="YYYY/MM/DD HH:mm"
                               placeholder="Fecha"
                ></a-date-picker>
              </a-form-model-item>
            </a-col>
            <!--TEXT - Razón de la transacción -->
            <a-col :sm="{ span: 24 }" :md="{ span:24}">
              <a-form-model-item label="Razón de la transacción" ref="reason_transaction"  prop="reason_transaction">
                <a-textarea v-model="resourceObj.form.reason_transaction" :rows="4" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row class="mt-3" align="middle" type="flex" justify="end">
            <a-col class="mr-4">
              <b-button @click="Submit"
                        variant="primary" pill block>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
            </a-col>
          </a-row>
        </a-form-model>
        <my-spinner :load="spinnerLoad" />
        <!--// Formulario-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'

export default {
  name: 'reagentTransactionTable',
  mixins: [fractalMixin],
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      sortByOptions: [],
      // Form
      spinnerLoad: false,
      resourceObj: {
        resourceType: 'reagentTransactions',
        form: {
          id: '',
          code: '',
          users: undefined,
          dated_at: '',
          warehouseTransactions: undefined,
          reagentProviders: undefined,
          reason_transaction: '',
          workCenters: undefined,
        },
        relationships: ['users', 'warehouseTransactions', 'reagentProviders', 'workCenters'],
      },
      resourceRules: {
        code: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        users: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        warehouseTransactions: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        reagentProviders: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        reason_transaction: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        workCenters: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
      },
      // Filter
      filterPlant1: '',
      filterFromDate: '',
      filterToDate: '',
      plantsCombo1: [],
    }
  },
  methods: {
    async fetchTable(pagEvent = {}) {
      console.log('Pagination', this.tableObject)
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : 1,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        include: 'users,workCenters,warehouseTransactions,reagentProviders,reagentTransactionDetails,reagentTransactionDetails.reagents',
      }
      if (this.filterValue !== '') params['filter[global]'] = this.filterValue
      if (this.filterPlant1 !== '') params['filter[workCenters]'] = this.filterPlant1
      if (this.filterFromDate !== '') params['filter[initialDatedAt]'] = this.filterFromDate
      if (this.filterToDate !== '') params['filter[finalDatedAt]'] = this.filterToDate
      console.log('Params', params)
      await this.GetTableResource(pagEvent, this.tableObject.resourceType, this.tableObject, params)
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
      ]
      const plants = await this.GetConcurrencyResources(arr)
      this.plantsCombo1 = plants
    },
    OpenModal(data = {}) {
      this.$refs.testModal.show()
      if (Object.keys(data).length === 0) {
        // Save
        this.ResetModal()
      } else {
        // Update
        this.resourceObj.form.id = data.id
        this.resourceObj.form.code = data.code
        this.resourceObj.form.users = data.users.id.toString()
        this.resourceObj.form.dated_at = data.dated_at
        this.resourceObj.form.warehouseTransactions = data.warehouseTransactions.id.toString()
        this.resourceObj.form.reagentProviders = data.reagentProviders.id.toString()
        this.resourceObj.form.reason_transaction = data.reason_transaction
        this.resourceObj.form.workCenters = data.workCenters.id.toString()
      }
    },
    CloseModal() {
      this.$refs.testModal.hide()
      this.ResetModal()
    },
    ResetModal() {
      this.resourceObj.form = {
        id: '',
        code: '',
        users: undefined,
        dated_at: '',
        warehouseTransactions: undefined,
        reagentProviders: undefined,
        reason_transaction: '',
        workCenters: undefined,
      }
    },
    Submit() {
      this.$refs.transactionForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            // Post
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else {
            // Put
            await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          }
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      this.CloseModal()
      await this.fetchTable()
    },
  },
  async mounted() {
    await this.fetchTable()
    await this.fetchCombos()
  },
}
</script>
